<template>
  <div class="resource-pool-list__wrapper">
    <CyResourcePoolListTable/>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'
import CyResourcePoolListTable from '@/components/resource-pool/list-table'

export default {
  name: 'CyPageResourcePools',
  components: {
    CyResourcePoolListTable,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.resourcePools'), [
      {
        label: this.$t('routes.resourcesSection'),
        name: 'resourcesSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.resourcesSection'),
      description: {
        text: this.$t('routes.resourcesSectionDescription'),
      },
    }
  },
  i18n: {
    messages: {
      en: {
        title: '@:ResourcePools',
      },
      es: {
        title: '@:ResourcePools',
      },
      fr: {
        title: '@:ResourcePools',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-pool-list__wrapper {
  @extend %cy-scrollbars;

  padding: 5px;
  overflow-y: auto;
}
</style>
